/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { GlobalProvider } from './src/components/global-context';

export const wrapPageElement = ({ element }) => (
  <GlobalProvider><AnimatePresence mode='wait'>{element}</AnimatePresence></GlobalProvider>
);

export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
  getSavedScrollPosition,
}) => {

  const transitionDelay = 600;
  window.history.scrollRestoration = "manual";

  // Check if the navigation is within the same page (only parameters changed)
  if (prevRouterProps && location.pathname === prevRouterProps.location.pathname) {
    // Maintain the current scroll position for in-page navigations (e.g., filter changes)
    return false;
  }

  // Check if the navigation is to the homepage
  if (location.pathname === "/") {
    // Delay the scroll to top until after the transition
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, transitionDelay);
    return false;
  }

  const currentPosition = getSavedScrollPosition(location) || [0, 0];

  setTimeout(() => {
    window.scrollTo(...currentPosition);
  }, transitionDelay );

  return false;
};
