import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';

// Create a Context
const GlobalContext = createContext();

// Provider Component
export const GlobalProvider = ({ children }) => {
  // Initiate states
  const [isMobile, setIsMobile] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [formProduct, setFormProduct] = useState(null);
  const [showHomeAnimation, setShowHomeAnimation] = useState(true);

  // Check if the window width corresponds to your mobile breakpoint
  const handleCheckBp = useCallback(() => {
    const isMobileNow = window.matchMedia(`(max-width: 899px)`).matches;
    if (isMobileNow !== isMobile) {
      setIsMobile(!isMobile);
    }
  }, [isMobile]);

  // Function to show overlay
  const handleShowOverlay = () => {
    setShowOverlay(true);
    document.body.classList.add('overlay-active');
  };

  // Function to hide overlay
  const handleHideOverlay = () => {
    setShowOverlay(false);
    document.body.classList.remove('overlay-active');
  };

  // Function to set form product
  const handleSetFormProduct = (productSlug) => {
    setFormProduct(productSlug);
  };

  // Function to disable home animation
  const handleDisableHomeAnimation = () => {
    setShowHomeAnimation(false);
  };

  // Set up event listener for resize events
  useEffect(() => {
    handleCheckBp(); // Check immediately on mount
    window.addEventListener('resize', handleCheckBp);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleCheckBp);
    };
  }, [handleCheckBp]);

  return (
    <GlobalContext.Provider value={{ isMobile, showOverlay, handleShowOverlay, handleHideOverlay, formProduct, handleSetFormProduct, showHomeAnimation, handleDisableHomeAnimation }}>
      {children}
    </GlobalContext.Provider>
  );
};

// Custom hook to use the global state
export const useGlobalState = () => useContext(GlobalContext);
